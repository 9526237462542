<template>
    <div class="sound">
        <span class="sound__btn" @click="handleAudioClick">{{ soundName }}</span>
        <audio class="sound__file" controls :src="waveFile"></audio>
    </div>
</template>

<script>
    export default {
        name: 'Sound',
        props: {
            file: {
                type: String
            }
        },
        computed: {
            soundName() {
                let soundName = this.file.split('.')[0]
                return soundName
            },
            waveFile() {
                return require(`../assets/audio/${this.file}`)
            }
        },
        methods: {
            handleAudioClick() {
                const sound = document.querySelector('.sound__file')
                sound.pause()
                sound.currentTime = 0.15
                sound.play()
            }
        }
    }
</script>

<style lang="scss">
    .sound {

        &__btn {
            padding: 20px;
            display: inline-block;
            box-shadow: 0px 3px 0px 0px #2f3942;
            margin-bottom: 10px;
            border-radius: 3px;
            background: #4f5f6f;
            color: #ffffff;
            text-shadow: 1px 1px 1px rgb(26 31 37 / 60%);
            background-color: #4f5f6f;
            text-transform: capitalize;
            cursor: pointer;

            &:hover,
            &:active {
                background: #64788d;
            }

            &:active {
                box-shadow: none !important;
                position: relative;
                top: 3px;
            }
        }
        
        &__file {
            display: none;
        }
    }
</style>
