<template>
    <div id="app">
        <h1>Gyaaaooouuuu !</h1>
        <div><img src="@/assets/img/villager.gif" alt=""></div>
        <div class="app__container">
            <Sound preload="auto" file="gyaaaooouuuu.wav" />
        </div>
        <p>Auteur : <a href="https://twitter.com/Gobolz" target="_blank">Gobolz</a></p>
    </div>
</template>

<script>
    import Sound from './components/Sound'
    export default {
        name: 'App',
        components: {
            Sound
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .app__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
</style>
